import React, { useEffect, useState } from 'react'

import { Table } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { fetchSingleProducts, getSkuFiles, selectedSku } from '../../redux/actions/productActions';
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import useSkuMatrix from './hooks/useSkuMatrix';
import { getSkuFiledsDetails } from "../../helper/api/skuMatrix"


interface Props {
    id: any,
    transformSkuValue?: any
}

function SkuMatrix({ id, transformSkuValue }: Props) {

    const singleData = useAppSelector((state: any) => state.product.singleProductData);
    const dispatch = useAppDispatch();
    const { skuFiles } = useSkuMatrix()
    const [tables, setTables] = useState<any>([])
    const [allFilePath, setAllFilePath] = useState<any>([])
    const [selected, setSelected] = useState<any>({ prev: null, curr: null })
    const [isOpen, setIsOpen] = useState<any>(false)
    const [imageIndex, setImageIndex] = useState<any>(0)
    const [length, setLength] = useState<any>(0)

    const [urlPath, setUrlPath] = useState<any>("")

    useEffect(() => {
        const hostName = window.location.hostname
        if (hostName === "fabricationbazar.com") {
            setUrlPath("https://admin.fabricationbazar.com/")
        } else if (hostName === "fb.arachnomesh.com") {
            setUrlPath("https://faraday-admin.arachnomesh.com/")
        } else if (hostName === "localhost") {
            setUrlPath("http://localhost:5005/")
        }
    }, [])

    useEffect(() => {
        if (skuFiles?.length) {
            const tempArray: any[] = [];
            // console.log(skuFiles, "sku")
            skuFiles.forEach((singleFile: any) => {
                // console.log(singleFile, "singleFile")
                Object.entries(singleFile).forEach(([key, value]: any) => {
                    if (key === "tagFile") {
                        Object.entries(value).forEach(([key, value]) => {
                            if (key === "tagId" && value === "30") {
                                tempArray.push(urlPath + singleFile?.fullPath)
                                return
                            }
                        })
                    }
                })

            })
            setAllFilePath(tempArray)
        }
        //eslint-disable-next-line
    }, [skuFiles])

    useEffect(() => {
        if (allFilePath.length) {
            console.log(allFilePath, "allFilePath")
            setLength(allFilePath.length)

        }
    }, [allFilePath])

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleProducts(id))
        }
        //eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (singleData?.sku_matrix) {
            const tables = singleData?.sku_matrix[Object.keys(singleData?.sku_matrix)[0]]
            setTables(tables)
        }
        //eslint-disable-next-line
    }, [singleData])

    useEffect(() => {
        if (selected.prev !== null) {
            selected.prev.style.backgroundColor = "white"
        }
        if ((selected.curr !== null)) {
            selected.curr.style.backgroundColor = "#d2d2d2"
        }
        //eslint-disable-next-line
    }, [selected])

    const handleSkuCellClick = ({ sku_id }: any) => {
        getSkuFiledsDetails({ product_id: id, sku_id: sku_id }).then((res) => {
            transformSkuValue(res.data?.response?.skuField, res.data?.response?.skuValue)
            // console.log(res, "dataSKUUU")
        }).catch((err) => {
            console.log(err, "error in Sku")
        })
    }

    return (
        <div>

            <div id='lightbox'>
                {isOpen && allFilePath.length && (< Lightbox
                    mainSrc={allFilePath[imageIndex]}
                    nextSrc={allFilePath[(imageIndex + 1) % length]}
                    prevSrc={allFilePath[(imageIndex + length - 1) % length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMoveNextRequest={() => {
                        setImageIndex((imageIndex + 1) % length)
                    }}
                    onMovePrevRequest={() => {
                        setImageIndex((imageIndex + length - 1) % length)
                    }}
                    imageTitle={imageIndex}
                />)}
            </div>

            {
                tables.map((row: any) => {
                    return (
                        <>

                            <div className='table-container table-responsive'>
                                <Table bordered className='dataCustomTable'>
                                    <thead>
                                        {row.rows.map((item: any, index: any) => {
                                            console.log(item, "sdbfsdf")
                                            return (
                                                <>
                                                    <tr>
                                                        {item.map((row: any, index: any) => {
                                                            if (row.type === 1) {
                                                                return (
                                                                    <th style={{ backgroundColor: row.color }} colSpan={row.col_size} >{row.value}</th>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <td id={`data${row.sku_id}`} colSpan={row.col_size} style={{ backgroundColor: row.color }}
                                                                            onClick={() => {
                                                                                if (row.sku_id !== 0 && row?.isAvailable !== false) {
                                                                                    // console.log(row, "rowdata")
                                                                                    setAllFilePath([])
                                                                                    handleSkuCellClick({ sku_id: row.sku_id })
                                                                                    // getSkuFields({product_id: productId.current, sku_id: row.sku_id })
                                                                                    dispatch(getSkuFiles({ id: row.sku_id, type: "sku" }))
                                                                                    setSelected({ prev: selected.curr, curr: document.getElementById(`data${row.sku_id}`) })
                                                                                    dispatch(selectedSku({ id: row.sku_id, value: parseFloat(row.value), file: row.file }))
                                                                                    setIsOpen(true)

                                                                                }
                                                                            }}> {row.value}</td >

                                                                    </>
                                                                )
                                                            }
                                                        })}

                                                    </tr >

                                                </>
                                            )
                                        })}
                                    </thead>
                                </Table>
                            </div >
                        </>
                    )

                }
                )
            }
        </div >



    )
}

export default SkuMatrix
