import axios from 'axios';
import { AuthService } from '../services/authService';

const authService = new AuthService();
let message: any;

axios.interceptors.response.use(
  (response) => {
    console.log(response, "res");
    return response;
  },
  (error) => {
    console.log(error.response, "err");

    switch (error.response.status) {
      case 401:
        authService.logout();
        // alert('Your session expired, please loging again');
        window.location.reload();
        break;
      case 400:
        message = error;
        break;
      default: {
        message = {
          message:
            error.response && error.response.data
              ? error.response.data["errorMsg"]
              : error.message || error,
          status: error.response.status,
        };
      }

    }
    return Promise.reject(message);
  }
);

const sessionId = localStorage.getItem('session');

axios.defaults.headers.common['Authorization'] = `Basic ${sessionId}`;

axios.defaults.baseURL = process.env.REACT_APP_ENV === 'development' ? "https://faraday-admin.arachnomesh.com/api/v1/" : process.env.REACT_APP_ENV === 'production' ? "https://admin.fabricationbazar.com/api/v1/" : process.env.REACT_APP_ENV === 'test' ? "https://fb-admin.arachnomesh.com/api/v1/" : process.env.REACT_APP_ENV === 'test-demo' ? "https://admin-demo.fabricationbazar.com/api/v1/" : "http://localhost:5005/api/v1/";


// axios.defaults.baseURL = "https://faraday-admin.arachnomesh.com/api/v1/"


//interceptor

axios.interceptors.request.use(config => {
  config.headers!.Authorization = `Basic ${localStorage.getItem('session')}`;
  return config;
}, error => {
  return Promise.reject(error);
});

//error in api catch block

export default axios;