/* eslint-disable */
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import landline from '../../assets/img/telephone.png';
import email from '../../assets/img/email.png';
import phone from '../../assets/img/phone.png';
// import facebook from '../../assets/img/facebook.png';
// import google from '../../assets/img/google.png';
// import instagram from '../../assets/img/instagram.png';
// import twitter from '../../assets/img/twitter.png';
// import linkedin from '../../assets/img/linkedin.png';
import React, { useState, useEffect } from 'react'
// import "../assets/css/contactus.css"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import * as yup from 'yup'
import "yup-phone"
import { sendQuery } from '../../redux/actions/enquiryActions';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
import { fetchCustomer } from '../../redux/actions/loginActions';
import { getCustomerDetails } from '../../redux/actions/loginActions';


function ContactUs() {
  const [phoneNumber, setPhoneNumber] = useState<any>("")
  // const navigate = useNavigate();

  const customer = useAppSelector((state: any) => state?.customerLogin?.customerExist);
  // console.log(customer, "customer customerExist in contact us")
  // const enquiry = useAppSelector((state: any) => state.query.query);
  const dispatch = useAppDispatch();

  const session = localStorage.getItem("session")
  const userId = localStorage.getItem("userId")


  useEffect(() => {
    if (session) {
      // alert(session)
      dispatch(fetchCustomer(userId))
      dispatch(getCustomerDetails(userId))
    }

  }, [userId, session])


  useEffect(() => {
    if (customer !== null) {
      console.log(customer, "formik")
      formik.values.name = customer?.user?.name
      formik.values.organization = customer?.organization_name
      formik.values.email = customer?.user?.email
      formik.values.designation = customer?.designation
      formik.values.cust_id = customer?.id
      formik.values.phone = customer?.user?.mobile
      setPhoneNumber(customer?.user?.mobile)
      // console.log(customer?.customer?.id, "customer id")
      // formik.values.website = customer?.customer?.website
    }
  }, [customer])


  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organization: '',
      designation: '',
      website: '',
      enquiry: '',
      cust_id: null,
      source: "Contact-us form",
      status: 46
    },

    validationSchema: yup.object({
      name: yup.string().required('Please enter your name'),
      email: yup.string().email('Invalid email address').required('Please enter your email'),
      organization: yup.string().required('Please enter your organization name'),
      enquiry: yup.string().required('Please enter your enquiry'),
      phone: yup.string().required('Phone number is required').matches(/^[6-9]\d{9}$/, 'Invalid phone number'),
      website: yup.string().matches(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm, 'Please enter valid website')
    }),
    onSubmit: (values) => {
      // let pNumber = phoneNumber.replace('+', '')
      // formik.values.phone = pNumber
      console.log(values, "values")
      let obj = { query: values };
      // let query: any = values
      dispatch(sendQuery(obj))
      toast.success("Thank you for your enquiry! We will get back to you soon.")

    },
  });

  return (
    <section className="contactSec" id="contact-us">
      <div className="container">

        <div className="row">
          <div className="col-lg-6">
            <div className="sectionTitle">
              <h6 className='text-brown'>CONTACT US</h6>
              <h1>Get in Touch  <br />
                with us!</h1>
            </div>

            <div className="contactDetails">
              <div className="contact">
                <h6 className='text-brown'>CALL US NOW </h6>
                <p> Mobile: +91 9007514346</p>
                <p> Landline: 011-46062261/62/63</p>
              </div>

              <div className="contact">
                <h6 className='text-brown'>HAVE A QUESTION ?</h6>
                <p><a href='mailto:queries@fabricationbazar.com;'>queries@fabricationbazar.com</a></p>
              </div>

              <div className="contact mb-3">
                <h6 className='text-brown'>MAIL US</h6>
                <p ><a href='mailto:sales@bengaws.com;'>sales@bengaws.com</a></p>
                <p ><a href='mailto:dwaipayan.dutta@bengaws.com;'>dwaipayan.dutta@bengaws.com</a></p>
              </div>

              <div className="contact mb-1">
                <h6 className='text-brown'>VISIT US</h6>
                <p>E 48/9, Millennium, Okhla industrial area <br /> phase-II, New-Delhi -110020, India</p>
              </div>




            </div>


          </div>
          <div className="col-lg-6">
            <div className="contactForm">

              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Form.Group as={Col} className='mb-3'>
                    <Form.Label> Name</Form.Label>
                    <Form.Control type="text" id='name' placeholder="Enter Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name} />
                    {formik.touched.name && formik.errors.name ? <p className='errrTxt'>{formik.errors.name}</p> : null}
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" id='email' placeholder="Enter email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                    {formik.touched.email && formik.errors.email ? <p className='errrTxt'>{formik.errors.email}</p> : null}
                  </Form.Group>

                </Row>

                <Row>

                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="number" id='phone' placeholder="Enter phone number" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.phone} />
                    {formik.touched.phone && formik.errors.phone ? <p className='errrTxt'>{formik.errors.phone}</p> : null}
                    {/* <PhoneInput
                      className='form-control'
                      placeholder="Enter phone number"
                      defaultCountry={country}
                      international={false}
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      onCountryChange={(val: any) => {
                        console.log("val", val)
                        if (val !== 'IN') {
                          alert(1)
                          setCountry('IN')
                        }
                      }}
                    /> */}
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Organisation Name</Form.Label>
                    <Form.Control type="text" id='organization' placeholder="Enter organization" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.organization} />
                    {formik.touched.organization && formik.errors.organization ? <p className='errrTxt'>{formik.errors.organization}</p> : null}
                  </Form.Group>

                </Row>

                <Row>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control type="text" id='designation'
                      placeholder='Enter Designation' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.designation} />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Website</Form.Label>
                    <Form.Control type="text" id='website' placeholder="Enter Website" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.website} />
                    {formik.touched.website && formik.errors.website ? <p className='errrTxt'>{formik.errors.website}</p> : null}
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Detailed Enquiry</Form.Label>
                  <Form.Control as="textarea" rows={3} id='enquiry' placeholder="Type Message" style={{ height: '100px' }} onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.enquiry} />
                  {formik.touched.enquiry && formik.errors.enquiry ? <p className='errrTxt'>{formik.errors.enquiry}</p> : null}
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>Document attachment</Form.Label>
                  <Form.Control type="file" />
                </Form.Group> */}

                <Button className='brownBtn w-100' variant="primary" type="submit">
                  Send Message
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>



  )
}

export default ContactUs