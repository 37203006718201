import React from 'react';
import { Col ,Row} from 'react-bootstrap';
import featureIcon1 from "../../assets/img/one_stop_solution.png";
import featureIcon2 from "../../assets/img/cost_benefit.png";
import featureIcon3 from "../../assets/img/logistics_cost_benefit.png";
import featureIcon4 from "../../assets/img/time_reduction.png";
import featureIcon5 from "../../assets/img/tech_integration.png";
import featureImg from "../../assets/img/feature.webp";


function FeaturesSec() {

  return (
    <div className="featuresSec">
      <Row>
        <Col lg={6}>

          <div className="featureTitle">
            <h2>Features </h2>
            <p>Fabrication Bazar offers superior quality, Best Price and fastest delivery in the manufacturing
              of pre-fab structures, large storage tanks, large storage bins etc.</p>
          </div>
          <div className="featuresDiv">
            <div className="feature">
              <img src={featureIcon1} alt='img' />
              <p>One Stop soultion for B2B Steel Fabricated Products</p>
            </div>
            <div className="feature">
              <img src={featureIcon2} alt='img' />
              <p>Minimum 5% Cost Benefit</p>
            </div>
            <div className="feature">
              <img src={featureIcon3} alt='img' />
              <p>Minimum 2% Logistics Cost Benefit</p>
            </div>
            <div className="feature">
              <img src={featureIcon4} alt='img' />
              <p>Minimum 30% Lead Time Reduction</p>
            </div>
            <div className="feature">
              <img src={featureIcon5} alt='img' />
              <p>Tech Integration for Easy Ordering and Visibility</p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="featureImg">
            <img src={featureImg} alt='img' />
          </div>
        </Col>
      </Row>

    </div>

  )
}

export default FeaturesSec;