import { useEffect, useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../assets/img/logo.png";
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { logout } from '../redux/actions/loginActions';
import { toast } from 'react-toastify';
import { getCustomerDetails } from '../redux/actions/loginActions';
import user1 from '../assets/img/user.png';

function Header() {

    const { customer, isValidSession } = useAppSelector((state: any) => {
        return {
            email: state.customerLogin,
            customer: state?.customerLogin?.customer?.response?.customer,
            isValidSession: state?.customerLogin?.isValidSession,
            verifySuccess: state.customerLogin
        }
    });




    const urlMapping: any = {
        "fb.arachnomesh.com": "faraday-admin.arachnomesh.com",
        "fb-demo.arachnomesh.com": "fb-admin.arachnomesh.com",
        "fabricationbazar.com": "admin.fabricationbazar.com",
        "localhost:3001": "localhost:3000"
    }

    const dispatch = useAppDispatch();
    const userLogin = sessionStorage.getItem("email") || localStorage.getItem("email")
    const userSession = sessionStorage.getItem("session") || localStorage.getItem("session")
    const protocol = useRef("https")
    const id = localStorage.getItem("userId")
    const [params,] = useSearchParams()


    useEffect(() => {
        if (params.has("logout")) {
            logoutHandler()
        }

        //eslint-disable-next-line
    }, [params])


    const logoutHandler = () => {
        sessionStorage.clear();
        localStorage.removeItem("email");
        localStorage.removeItem("session");
        localStorage.removeItem("key");
        localStorage.removeItem("userId");
        localStorage.removeItem("r");
        localStorage.removeItem("c");
        localStorage.removeItem("sFlag");
        localStorage.removeItem('token');
        localStorage.removeItem('userType');
        dispatch(logout());
        toast.success("Logged out successfully");
    }




    useEffect(() => {

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            protocol.current = "http"
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (isValidSession && userSession && id) {
            dispatch(getCustomerDetails(id))
        }
        //eslint-disable-next-line
    }, [isValidSession])



    return (
        <Navbar expand="lg" className='header'>
            <Navbar.Brand>
                <div className='logo'>
                    <Link to="/"> <img src={logo} alt="logo" /></Link>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/" className="nav-link"> Home</Nav.Link>
                    <Nav.Link href="/productDetail" className="nav-link"> Products</Nav.Link>
                    <Nav.Link href='/#about-us' className="nav-link" style={{ color: "#fff" }}> About us</Nav.Link>
                    <Nav.Link href="/#contact-us" className="nav-link" style={{ color: "#fff" }}>Contact us</Nav.Link>
                    {userLogin && userSession ? (
                        <>
                            <a href={`${protocol.current}://${urlMapping[window.location.host]}/login?source=${window.location.host}&sid=${userSession}`} rel="noreferrer" className="nav-link">Dashboard</a>
                            <div className='d-flex align-items-center'>
                                <span><img src={user1} className='userIcon' alt="user" /></span>
                                <NavDropdown title={customer?.user?.name}>
                                    <NavDropdown.Item href="/" onClick={logoutHandler}>
                                        Logout</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </>
                    ) : (
                        <>
                            <Nav.Link href='/login' className='btn brownBtn mx-3'>Login</Nav.Link>
                            <Nav.Link href='/register' className='btn borderBtn'>Sign up</Nav.Link>

                        </>
                    )}

                </Nav>
            </Navbar.Collapse>
        </Navbar >
    )
}

export default Header
