import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from "../assets/img/logo_Dark.png";
// import facebook from "../assets/img/facebook.png";
// import twitter from "../assets/img/twitter.png";
// import google from "../assets/img/googlev.png";
// import linkedin from "../assets/img/linkedin.png";
// import instagram from "../assets/img/instagram.png";


function Footer() {

  return (
    <div className='footerSec' id='footer'>
      <Container>
        <Row>
          <Col lg={6}>
            <div className='footerDetails'>
              <img src={logo} className='logo_dark mb-3' alt="logo" />

{/*               
              <div className='socialIcons'>
                <a href='www.google.com' target='_blank'>  <img src={facebook} alt="facebook" />  </a>
                <a href='www.instagram.com' target='_blank'>  <img src={instagram} alt="instagram" />  </a>
                <a href='www.google.com' target='_blank'>  <img src={linkedin} alt="linkedin" />  </a>
                <a href='www.google.com' target='_blank'>  <img src={twitter} alt="twitter" />  </a>
                <a href='www.google.com' target='_blank'>  <img src={google} alt="google" />  </a> </div> */}


            </div>
          </Col>

          <Col lg={6}>
          <div className='copyright text-end'>
          <a href="privacyPolicy" style={{textDecoration:'none'}}><p>Privacy Policy</p></a> 
            <p>Copyright © 2022. All Rights Reserved</p>
           {/* <a href="termsCondition"><p>Terms & Condition</p></a>  */}


          </div>


          </Col>



        </Row>

      

      </Container>


    </div>
  )
}

export default Footer